import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { get } from '@vueuse/core';
import type { ApiResponse } from '~/types';

interface LocationCacheData {
  formFlows: []
  groups: { id: number, name: string }[]
  nfc: { id: string, value: string }[]
  rooms: []
}

export function useFetchLocationCache(locationId: MaybeRef<number>) {
  const { user } = useAuth();

  const queryClient = useQueryClient();

  return useQuery({
    enabled: Boolean(locationId),
    refetchOnWindowFocus: true,
    queryKey: ['locationCache', { locationId, userId: get(user)?.id }],
    queryFn: async () => {
      const { data, error } = await useApi<ApiResponse<LocationCacheData>>(
        `/api/v2/location/${unref(locationId)}/cached-data`,
      );

      if (error.value)
        throw error.value;

      queryClient.setQueryData(['formFlows', locationId], get(data)!.data.formFlows);

      return get(data)!.data;
    },
    staleTime: 1000 * 60 * 60,
  });
}
